.row-category-cont {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  @include themify($themes) {
    background-color: themed("colorBackgroundBody");
  }

  @include themify($themes) {
    color: themed("colorText");
  }

  @media screen and (max-width:992px) {
    flex-direction: column;
  }
}

.card-cont {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  min-width: 300px;
  margin:10px;
  height: 100px;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.8);
  border-style:groove;
  cursor: pointer;
  @include themify($themes) {
    background-color: themed("colorBackground");
  }

  @include themify($themes) {
    color: themed("colorText");
  }

  @media screen and (max-width:992px) {
    flex: auto;
  }
}

.settings-main-cont {
  margin:20px;
}

.main-category-cont {
  @media screen and (max-width:992px) {
    margin-top: 50px;
  }
}