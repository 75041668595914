.time-range-selection {
  // flex-direction: column;
  // align-items: center;

  @include themify($themes) {
    background-color: themed("colorBackgroundBody");
  }

  @include themify($themes) {
    color: themed("colorText");
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    @include themify($themes) {
      background-color: themed("colorBackgroundBody");
    }
  }

  .react-datepicker__input-container input {
    margin-bottom: 2px;
    border-radius: 5px;

    @include themify($themes) {
      background-color: themed("colorBackground");
    }
  
    @include themify($themes) {
      color: themed("colorText");
    }
  }

  .react-datepicker__day--today,
  .react-datepicker__header,
  .react-datepicker {
    @include themify($themes) {
      background-color: themed("colorHover");
    }
  
    @include themify($themes) {
      color: themed("colorText");
    }
  }

  .react-datepicker__time-list {
    @include themify($themes) {
      background-color: themed("colorHover");
    }
  
    @include themify($themes) {
      color: themed("colorText");
    }
  }
}